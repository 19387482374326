import React from "react";
import BlogList from "../../BlogList/BlogList";
import FeaturedBlog from "../../FeaturedBlog/FeaturedBlog";
// import NewsletterSignup from "../../NewsletterSignup/NewsletterSignup";
import * as styles from "./BlogSection.module.css";

export default function BlogSection() {
  return (
    <div className={styles.section}>
      <FeaturedBlog />
      {/* <NewsletterSignup /> */}
      <BlogList />
    </div>
  );
}
