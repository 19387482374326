import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as styles from './BlogList.module.css';
import BlogCard from '../BlogCard/BlogCard';

export default function BlogList() {
    const query = graphql`
        query AllBlogQuery {
            strapiFeatured {
                blog {
                    id
                }
            }
            allStrapiBlog {
                edges {
                    node {
                        slug
                        id
                        strapiId
                        title
                        Seo {
                            ShareImage {
                                media {
                                    url
                                }
                                alt
                            }
                        }
                    }
                }
            }
        }
    `
    return (
        <StaticQuery
            query={query}
            render={data => {
                const featId = data.strapiFeatured.blog.id
                const blogs = data.allStrapiBlog.edges
                    .filter(b => b.node.strapiId !== featId)
                    .sort((a,b) =>{
                        return a.node.strapiId - b.node.strapiId
                    })
                    .map((b, i) => (
                        <BlogCard key={`blog-${i}`} blog={b.node} />
                    ))
                return (
                    <div className={styles.blogList}>
                        {blogs}
                    </div>
                )
            }}
        />
    )
}