import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header/Header";
import BlogSection from "../components/sections/BlogSection/BlogSection";
import Footer from "../components/Footer/Footer";
import "../styles/global.css";

const Resources = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Per Diem Nursing Resources | Para</title>
        <meta
          name="description"
          content="Do you have questions about per diem nursing pay, benefits, or something else? Click here to find answers to the most common per diem nursing questions!"
        />
        <link rel="canonical" href="https://joinpara.com/resources/" />
      </Helmet>
      <Header />
      <BlogSection />
      <Footer />
    </>
  );
};

export default Resources;
