import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import * as styles from "./FeaturedBlog.module.css";

export default function FeaturedBlog() {
  const query = graphql`
    query {
      strapiFeatured {
        blog {
          title
          slug
          id
          keywords
          Seo {
            metaDescription
            ShareImage {
              alt
              media {
                url
              }
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const blog = data.strapiFeatured.blog;
        const { alt, media } = blog.Seo.ShareImage;
        return (
          <div className={styles.featured}>
            <h1 className={styles.featuredTitle}>
              Your Resource For Everything <br /> Per Diem Nursing
            </h1>
            <div className={styles.featuredBlog}>
              <div className={styles.content}>
                <h4 className={styles.keywords}>{blog.keywords}</h4>
                <h2 className={styles.title}>{blog.title}</h2>
                <p className={styles.description}>{blog.Seo.metaDescription}</p>
                <Link
                  to={"/resources/" + blog.slug + "/"}
                  className={styles.button}
                >
                  Read More
                </Link>
              </div>
              <img src={media.url} alt={alt} className={styles.img} />
            </div>
          </div>
        );
      }}
    />
  );
}
