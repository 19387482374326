import React from "react";
import { Link } from "gatsby";
import * as styles from "./BlogCard.module.css";

export default function BlogCard(props) {
  const blog = props.blog;
  const { media, alt } = blog.Seo.ShareImage;
  return (
    <Link className={styles.card} to={"/resources/" + blog.slug + "/"}>
      <img className={styles.img} src={media.url} alt={alt} />
      <h4>{blog.title}</h4>
    </Link>
  );
}
